.property-owner-list-details {
    height: 550px;
    overflow-y: scroll !important;

    @media (min-width:760px) and (max-width:770px) {
        margin-right: 20px !important;
    }

    .assign-card {
        padding: 10px 0 0 10px;
        margin-bottom: 15px;

        @media (max-width:575px) {
            padding: 0px !important;
        }

        .MuiBox-root {
            padding: 10px !important;
        }

        .MuiMobileStepper-root {
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #ffff !important;
            margin-top: -130px !important;
            margin-left: -20px !important;
            margin-right: -23px !important;

            @media (min-width:575px) and (max-width:915px) {
                margin-top: -100px !important;
            }

            @media (min-width:915px) and (max-width:1024px) {
                margin-top: -100px !important;
            }

            @media (min-width:431px) and (max-width:575px) {
                margin-top: -190px !important;
            }
        }

        .MuiPaper-root {
            background-color: transparent !important;
            color: #ffff !important;
        }

        .MuiButtonBase-root {
            display: inline-block;
            justify-content: inherit !important;
        }

        .rent-box {
            padding-top: 32% !important;

            .rental-price {
                font-size: 22px;
                font-weight: 600;
                padding: 10px 6px 5px 2px;
                font-family: "Proxima Nova";
            }

            .rent-heading {
                display: flex;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 25px;
                font-weight: 600;
                padding: 8px 0 5px 10px;
                font-family: "Proxima Nova";
            }

        }

        .deposit-type {
            display: none !important;
        }

        .mobile-type {
            display: none !important;

            .rental-heading {
                padding-top: 70px;
                color: #707070;
                font-size: 16px;
                font-family: "Proxima Nova";
                display: flex;
                justify-content: center;
            }

            .rental-price {
                font-size: 16px;
                font-weight: 600;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

            .rent-heading {
                font-size: 16px;
                font-weight: 500;
                color: #707070;
            }

            .deposit-price {
                font-size: 16px;
                font-weight: 600;
                padding-left: 7px;
                font-family: Proxima_nova_reg;
            }

        }

        .property-detail-conatiner {

            @media (max-width:575px) {
                padding-left: 10px !important;
                padding-right: 10px !important;
            }

            .user-heading {
                font-size: 18px !important;
                font-weight: 600 !important;
                font-family: "Proxima Nova";
            }

            .user-dot {
                font-size: 20px;
                font-weight: 800;
                margin: -6px 10px 0 10px;
            }

            .property-owner {
                color: #707070;
                font-size: 18px;
                font-weight: 500;
            }

            .request-profile {
                background-color: #2F86D1;
                color: #ffffff;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: 600;
                // padding-right: 3px;
                padding-top: 3px;
                cursor: pointer;
                margin-right: 20px;
            }

            .categroy-heading {
                color: #707070;
                font-size: 15px;
                font-weight: 500;
                margin-left: 8px;
                margin-top: 3px;
            }

            .category-value {
                font-size: 18px;
                font-weight: 600;
                margin-left: 10px;
                margin-top: 1px;
            }

            .select-value-heading {
                font-size: 16px;
                font-weight: 600;
                margin-top: 10px;
            }

            .view-heading {
                font-size: 18px;
                font-weight: 600;
                color: #2F86D1;
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }

}

@media (min-width:768px) and (max-width:991px) {
    .property-owner-list-details .assign-card .rent-box {
        padding-top: 42% !important;
    }

    .property-owner-list-details .assign-card .deposit-type {
        padding-top: 42% !important;
    }
}

@media (min-width:808px) and (max-width:913px) {
    .property-owner-list-details .assign-card .rent-box {
        display: none !important;
    }

    .property-owner-list-details .assign-card .deposit-type {
        padding-top: 23% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (min-width:770px) and (max-width:808px) {
    .property-owner-list-details .assign-card .rent-box {
        display: none !important;
    }
}

@media (min-width:1200px) and (max-width:1523px) {
    .property-owner-list-details .assign-card .rent-box {
        padding-top: 33% !important;
    }

}

@media (min-width:575px) and (max-width:768px) {
    .property-owner-list-details .assign-card .rent-box {
        display: none !important;
    }

    .property-owner-list-details .assign-card .deposit-type {
        padding-top: 30% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (max-width:575px) {
    .property-owner-list-details .assign-card .mobile-type {
        margin-top: 10px !important;
        display: flex !important;
    }
    .property-owner-list-details .assign-card .rent-box {
        display: none !important;
    }
}

@media (min-width:430px) and (max-width:575px) {
    .card-detail-container .rent-box {
        display: none !important;
    }

    .property-owner-list-details .assign-card .property-detail-conatiner .section-profile {
        margin-top: 155px !important;
    }
}

@media (max-width:430px) {
    .property-owner-list-details .assign-card .rent-box {
        display: none !important;
    }

    .property-owner-list-details .assign-card .property-detail-conatiner .section-profile {
        margin-top: 85px !important;
    }
}