// .profile-heading{
//     font-size: 20px;
// }
// .ppCircle{
//     border-radius: 50%;
//     border: none !important;
//     img{
//         border-radius: 50%;
//     }
// }
// .editBtn{
//     border-radius: 50%;
//     position: relative;
//     top: -40px;
//     left: 53px;
// }
// .main-heading{
//     font-size: 32px;
//     font-weight: bold;
//     font-family: "Proxima_nova_bold";
//     margin-top: -25px;
// }
// .main-text{
//     margin-left: 8px;
//     font-size: 16px;
//     font-weight: 600;
//     font-family: "Proxima Nova";
// }
// .input-fields{
//     margin-top: 30px;
//     height: 430px;
//     position: relative;
//     margin-bottom: 30px;


//     .MuiFormLabel-root {
//         color:#0075CA !important ;
//     }
//     .name_field{
//         .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd::before {
//             border-bottom: none !important;
//           }

//         .MuiFormControl-root {
//             border-bottom: 1px solid #70bef7;

//         }
//     }

// }
// .footer-text{
//     max-width: 400px;
//     font-family: "Proxima Nova";
// }
// .footer-text-simple{
//     font-size: 18px;
// }
// .footer-text-verify{
//     font-size: 20px;
//     font-weight: 600;
//     color: #2F86D1;
// }
// @media (min-width:1100px) and (max-width:1300px) {
//     .profile-heading{
//         font-size: 16px !important;
//     }
//     .main-heading{
//         font-size: 25px !important;
//     }
//     .main-text{
//         font-size: 14px !important;
//     }
//     .footer-text-simple{
//         font-size: 16px !important;
//     }
//     .footer-text-verify{
//         font-size: 16px !important;
//     }
// }
// @media (max-width:1150px) {
//     .profile-heading{
//         font-size: 14px !important;
//     }
//     .main-heading{
//         font-size: 22px !important;
//     }
//     .main-text{
//         font-size: 12px !important;
//     }
//     .footer-text-simple{
//         font-size: 14px !important;
//     }
//     .footer-text-verify{
//         font-size: 14px !important;
//     }
// }
// .savechanges_button{
//     width: 217px !important;
// }

.profile-heading {
    font-size: 20px;
}

.profile-type-heading {
    font-size: 20px;
    font-weight: 500;
    color: #189FEA;
}

.ppCircle {
    border-radius: 50%;
    border: none !important;

    img {
        border-radius: 50%;
    }

}

.loader-container {
    border-radius: 50%;
    border: none !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 12px !important;

    img {
        border-radius: 50%;
    }

    .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #3498db;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        /* Safari */
        animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

}

.editBtn {
    border-radius: 50%;
    position: relative;
    top: -40px;
    left: 53px;
}

.main-heading {
    font-size: 32px;
    font-weight: bold;
    font-family: "Proxima_nova_bold";
    margin-top: -25px;
}

.main-text {
    margin-left: 8px;
    font-size: 16px;
    font-weight: 600;
    font-family: "Proxima Nova";
}

.input-fields {
    margin-top: 30px;
    height: 430px;
    position: relative;
    margin-bottom: 30px;


    .MuiFormLabel-root {
        color: #0075CA !important;
    }

    .name_field {
        .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedEnd::before {
            border-bottom: none !important;
        }

        .MuiFormControl-root {
            border-bottom: 1px solid #70bef7;

        }
    }

    .button-login {
        position: absolute;
        bottom: 0;
        right: 0 !important
    }

    .savechanges_button {
        width: 217px !important;
    }

}

.footer-text {
    max-width: 400px;
    font-family: "Proxima Nova";
}

.footer-text-simple {
    font-size: 18px;
}

.footer-text-verify {
    font-size: 20px;
    font-weight: 600;
    color: #2F86D1;
}

@media (min-width:1100px) and (max-width:1300px) {
    .profile-heading {
        font-size: 16px !important;
    }

    .profile-type-heading {
        font-size: 18px !important;
    }

    .main-heading {
        font-size: 25px !important;
    }

    .main-text {
        font-size: 14px !important;
    }

    .footer-text-simple {
        font-size: 16px !important;
    }

    .footer-text-verify {
        font-size: 16px !important;
    }
}

@media (max-width:1150px) {
    .profile-heading {
        font-size: 14px !important;
    }

    .profile-type-heading {
        font-size: 16px !important;
    }

    .main-heading {
        font-size: 22px !important;
    }

    .main-text {
        font-size: 16px !important;
    }

    .footer-text-simple {
        font-size: 14px !important;
    }

    .footer-text-verify {
        font-size: 14px !important;
    }
}

@media (max-width:414px) {
    .input-fields .savechanges_button {
        width: 208px !important;
    }
}

@media (max-width:575px) {
    .input-fields .button-login {
        // right: 0 !important;
        display: flex !important;
        justify-content: center !important;
        position: relative;
        top: 10% !important;
        // padding-left: 10px !important;
    }
}