.subscription-package-container {
    margin-top: 20px;
    // width: 1000px;
    height: auto;
    background-color: #FFFFFF;
    padding: 20px 30px;
    box-shadow: 0px 2px 8px 0px #0000001F;

    @media (min-width:400px) and (max-width:430px) {
        margin-bottom: 80px !important;
    }

    .current-heading {
        font-size: 18px;
        font-weight: 400;
    }

    .active-heading {
        font-size: 24px;
        font-weight: 600;
        color: #919CA5;
        margin-top: 25px;
    }

    .active-description {
        font-size: 16px;
        font-weight: 400px;
        margin-top: 10px;
    }

    .subscription-package {
        padding-top: 137px !important;
        font-weight: 700;
        font-size: 24px;

        @media (max-width:575px) {
            font-size: 20px !important;
            padding-top: 32px !important;
        }

        @media (min-width:760px) {
            margin-bottom: 10px !important;
        }
    }

    .subscription-button {
        border: none;
        background-color: white;
        font-size: 24px;
        padding-top: 20px;
        font-weight: 600;
    }

    .Manager-Button {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        gap: 10px;
    }


    .subscription-box {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;

        .item-heading {
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            margin-top: 15px;
            margin-bottom: 10px;
            color: #505050;
        }

        .item-below-description {
            margin-top: 5px;
            margin-left: 2px;
            font-size: 16px;
            font-weight: 500;
            color: #919CA5;
        }

        .subscribe-button {
            cursor: pointer;
            height: 167px;
            padding-top: 20px;

            @media (max-width:575px) {
                margin-top: 20px !important;
                height: 120px;
                padding-top: 14px;
            }

            .amount-term {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0075CA;
                font-size: 24px;
                font-weight: 700;
            }

            .listing-amount {
                color: #505050;
                font-weight: 700;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 20px;

                @media (max-width:575px) {
                    padding-top: 12px !important;
                }
            }
        }
    }

    .subscription-price {
        font-size: 24px;
        font-weight: 700;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .Manager-freeTrial {
        font-size: 20px;
        padding: 10px;
        color: #505050;

        @media (max-width:575px) {
            font-size: 16px !important;
        }


    }

    .checkout-button {
        display: flex;
        justify-content: end;
        margin-top: 40px;
        // margin-right: 20px;

        @media (max-width:575px) {
            margin-right: 0px !important;
            // justify-content: center !important;
        }
    }
}