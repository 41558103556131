// .home {
//     background-image: url("../../assets/images/HomeBackground.png");
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: cover;
//     height: fit-content;
//     min-height: 100vh;
//     display: flex;
//     justify-content: center;
//     font-family: Proxima_nova_medium;
//     background-attachment: fixed;

//     .middleContent {
//         margin-top: 50px;

//         .logo,
//         .logoHome {
//             margin-bottom: 10vh;

//             img {
//                 max-width: 166px;
//             }
//         }

//         .logo,
//         .logoHome .banner {
//             display: flex;
//             justify-content: center;

//         }

//         .bottomContent {
//             width: 100%;
//             text-align: center !important;

//             h3 {
//                 font-size: var(--big-heading);
//                 margin: 0;
//                 max-width: 660px;
//             }

//             .main-heading {
//                 font-family: "Proxima_nova_reg";
//                 font-size: 24px;
//                 font-weight: 600;
//                 margin: 0;
//                 max-width: 660px;
//             }

//             p {
//                 font-size: var(--medium-heading);
//                 font-weight: 400;
//                 font-family: "Proxima_nova_reg";
//             }

//             .main-description {
//                 font-size: 18px;
//                 font-weight: 400;
//                 font-family: "Proxima_nova_reg";
//             }

//             .link {
//                 color: var(--primary);
//                 font-size: 20px;
//                 font-family: Proxima_nova_medium;
//                 border-bottom: 0.5px solid var(--primary);
//                 max-width: 327px;
//                 margin-top: 24px;
//                 line-height: 1.2;
//                 cursor: pointer;
//             }

//             .lableContent {
//                 font-size: 16px;
//                 font-family: "Proxima_nova_reg";
//                 cursor: pointer;
//             }

//             .checkbox {
//                 padding: 0 20px;

//                 .lableContent {
//                     text-align: start;
//                 }
//             }

//             .form-check-input[type=checkbox] {
//                 border: 1.5px solid #1C1B1F;
//                 border-radius: 0;
//             }

//             .home-value {
//                 // display: flex;
//                 width: 100%;
//                 font-size: 1.25rem;
//                 flex-wrap: wrap;
//                 // justify-content: center;
//                 margin-bottom: 30px;
//                 gap: 5px;
//             }

//             .buttton-value {
//                 @media (max-width:430px) {
//                     font-size: 14px !important;
//                 }
//             }

//             .bottom {
//                 display: flex;
//                 width: 100%;
//                 font-size: 1.25rem;
//                 flex-wrap: wrap;
//                 justify-content: center;
//                 margin-bottom: 30px;
//                 gap: 5px;
//             }
//         }
//     }

//     @media screen and (max-width:576px) {
//         .middleContent {
//             margin-top: 40px;
//             padding: 0 10px;
//         }

//         .logo,
//         .logoHome {

//             margin-bottom: 4vh !important;
//             overflow-x: hidden !important;
//         }

//         .logoImg {
//             width: 104px;
//         }

//         .bannerImg {
//             max-width: 100vw;
//         }

//         .bottomContent {
//             // position: absolute;
//             bottom: 0;
//             left: 0;
//             right: 0;
//             max-width: 530px;
//             margin: auto;
//             margin-bottom: 12px;
//             overflow-x: hidden !important;
//         }

//     }

//     @media (min-width:575px) {
//         .home .middleContent .bottomContent .center-buttons {
//             display: flex !important;
//             flex-direction: column !important;
//             align-items: center !important;
//             justify-content: center !important;
//         }

//         .bottomContent {
//             // position: absolute;
//             overflow-x: hidden !important;
//         }
//     }

//     @media screen and (min-width:500px) and (max-width:576px) {
//         .home-value {
//             // width: 50% !important;
//             margin: auto !important;
//         }
//     }


//     @media screen and (max-width:1200px) {
//         .logo {
//             visibility: hidden !important;
//         }
//     }
// }

.home {
    background-image: url("../../assets/images/HomeBackground.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: fit-content;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    font-family: Proxima_nova_medium;
    background-attachment: fixed;
    overflow-x: hidden;

    .middleContent {
        margin-top: 50px;

        .logo,
        .logoHome {
            margin-bottom: 10vh;

            img {
                max-width: 166px;
            }
        }

        .logo,
        .logoHome .banner {
            display: flex;
            justify-content: center;

        }

        .bottomContent {
            width: 100%;
            text-align: center;

            h3 {
                font-size: var(--big-heading);
                margin: 0;
                max-width: 660px;
            }

            .main-heading {
                font-family: "Proxima_nova_reg";
                font-size: 24px;
                font-weight: 600;
                margin: 0;
                max-width: 660px;

                @media (max-width:540px) {
                    font-size: 16px !important;
                }
            }

            p {
                font-size: var(--medium-heading);
                font-weight: 400;
                font-family: "Proxima_nova_reg";
            }

            .main-description {
                font-size: 18px;
                font-weight: 400;
                font-family: "Proxima_nova_reg";
            }

            .link {
                color: var(--primary);
                font-size: 20px;
                font-family: Proxima_nova_medium;
                border-bottom: 0.5px solid var(--primary);
                max-width: 327px;
                margin-top: 24px;
                line-height: 1.2;
                cursor: pointer;
            }

            .lableContent {
                font-size: 16px;
                font-family: "Proxima_nova_reg";
                cursor: pointer;

                @media (max-width:540px) {
                    font-size: 14px !important;
                }
            }

            .login-label {
                color: #0075CA;
                font-weight: 600;
                font-size: 20px;
                cursor: pointer;
                margin-top: 29px;
                text-decoration: underline;

                @media (max-width:540px) {
                    font-size: 16px !important;
                    margin-top: 16px !important;
                }
            }

            .checkbox {
                padding: 0 20px;

                .lableContent {
                    text-align: start;
                }
            }

            .form-check-input[type=checkbox] {
                border: 1.5px solid #1C1B1F;
                border-radius: 0;
            }

            .home-value {
                // display: flex;
                width: 100%;
                font-size: 1.25rem;
                flex-wrap: wrap;
                // justify-content: center;
                margin-bottom: 30px;
                gap: 5px;
            }

            .buttton-value {
                @media (max-width:430px) {
                    font-size: 14px !important;
                }
            }

            .center-buttons {
                display: none !important;
                // flex-direction: column !important;
                // align-items: center !important;
                // justify-content: center !important;
            }

            .center-buttons .button {
                width: 100% !important;
                height: 50px !important;
                margin-bottom: 10px !important;
                /* Adjust as needed */
            }
        }
    }


    @media screen and (max-width:576px) {
        .middleContent {
            margin-top: 40px;
            padding: 0 10px;
        }

        .logo,
        .logoHome {

            margin-bottom: 4vh !important;
        }

        .logoImg {
            width: 104px;
        }

        .bannerImg {
            max-width: 100vw;
        }

        .bottomContent {
            // position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            max-width: 530px;
            margin: auto;
            margin-bottom: 12px;
            overflow-x: hidden !important;
        }
    }

    @media (min-width:575px) {
        .home .middleContent .bottomContent .center-buttons {
            display: flex !important;
            flex-direction: column !important;
            align-items: center !important;
            justify-content: center !important;
        }

        .bottomContent {
            // position: absolute;
            overflow-x: hidden !important;
        }
    }

    @media screen and (min-width:500px) and (max-width:576px) {
        .home-value {
            // width: 50% !important;
            margin: auto !important;
        }
    }

    @media screen and (max-width:1200px) {
        .logo {
            visibility: hidden !important;
        }
    }
}