.update-heading {
    margin-left: 70px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 10px;
}

.update-lising-container {
    width: 440px !important;
    height: auto;
    background-color: #ffffff;
    margin-left: 60px;
    padding: 15px;
    border-bottom: 1px solid #909090;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .users-heading {
        padding-left: 20px;
        padding-top: 5px;

        .user-main-heading {
            font-size: 16px;
            font-weight: 500;
            color: #000000;
        }

        .user-heading {
            font-size: 16px;
            font-weight: 500;
            color: #909090;
        }
    }

    .request-profile {
        background-color: #2F86D1;
        color: #ffffff;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        cursor: pointer;
    }
}

.chat-heading {
    margin-left: 30px;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 500;
}

.chat-contact-detail-main {
    display: flex;
    align-items: end;
    width: 840px;
    height: 78vh;
    background-color: #ffffff;
    margin-left: 20px;
    margin-top: 10px;
    box-shadow: 0px 2px 8px 0px #0000001F;

    .chat-container {
        width: 840px;
        padding: 15px;

        .chat-contact-left {
            width: 320px;
            height: auto;
            background-color: #D9EFFF;
            padding: 10px;

            .listing-user-heading {
                font-size: 23px;
                font-weight: 600;
                color: #2F86D1;
            }

            .listing-user-month {
                display: flex;
                align-self: center;
                color: #2F86D1;
                margin-top: 5px;
                font-size: 16px;
                font-weight: 600;
            }

            .chat-description {
                font-size: 16px;
                margin-top: 4px;
            }

            .chat-bed-heading {
                font-size: 18px;
                font-weight: 500;
                color: #505050;
                padding-left: 10px;
            }

            .chat-sqare {
                font-size: 18px;
                font-weight: 500;
            }
        }

        .chat-contact-left-2 {
            width: 380px;
            height: auto;
            background-color: #D9EFFF;
            margin-top: 6px;
            padding: 3px 7px 3px 15px;

            .chat-contact-heading {
                font-size: 18px;
                font-weight: 600;
                color: #303030;
            }
        }

        .chat-contact-right {
            width: 180px;
            height: auto;
            background-color: #0075CA;
            color: #ffffff;
            padding: 10px;
            border-radius: 5px;
            margin-top: 10px;

            .chat-contact-right-heading {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .MuiFilledInput-root.Mui-focused {
            outline: none !important;
            border: none !important;
        }
    }
}