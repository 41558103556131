// .arrow-box {
//     background-color: #ffffff;
//     width: 60px;
//     height: 35px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
// }

// .carousel-item.selected img {
//     border: 4px solid #0075CA !important;
//     padding: 5px;
//     width: 95%;
//     outline: none;
// }

// .carousel-item.selected {
//     outline: none;
// }

// .carousel-container {
//     position: relative;
//     margin-top: 10px;
// }

// .custom-arrow {
//     position: absolute;
//     top: 60%;
//     transform: translateY(-50%);
//     cursor: pointer;
//     z-index: 1;
// }

// .prev-arrow {
//     right: calc(100% - 36px);
// }

// .next-arrow {
//     left: calc(100% - 36px);
// }

// .carousel-item {
//     margin: 0 1px;
//     /* Adjust the margin as needed */
// }

// .slick-slider {
//     padding-top: 15px;
// }

// .carousel-item.selected img {
//     border: 4px solid #0075CA;
//     padding: 5px;
//     width: 95%;
//     outline: none;
// }

// .carousel-item.selected {
//     outline: none;
// }

.property-detail-section {
    width: 750px;
    height: auto !important;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .user-heading {
        font-size: 18px !important;
        font-weight: 600 !important;
        font-family: "Proxima Nova";
    }

    .user-dot {
        font-size: 20px;
        font-weight: 800;
        margin: -6px 10px 0 10px;
    }

    .property-owner {
        color: #707070;
        font-size: 18px;
        font-weight: 500;
    }

    .availability-heading {
        font-size: 18px;
        font-weight: 500;
        color: #0075CA;
        padding-top: 5px;
        padding-left: 10px;
        cursor: pointer;
    }

    .accept-box {
        width: 150px;
        height: 40px;
        background-color: #0075CA;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;

        .accept-heading {
            font-size: 14px;
            font-weight: 600;
            color: #ffffff;
            padding-left: 7px;
        }
    }

    .reject-box {
        width: 150px;
        height: 40px;
        margin-left: 12px;
        background-color: #ffffff;
        border: 1px solid #0075CA;
        color: #0075CA;
        padding: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        cursor: pointer;

        .reject-heading {
            font-size: 14px;
            font-weight: 600;
            color: #0075CA;
            padding-left: 7px;
        }
    }


    .deposit-section {

        .amount-seacrh {
            font-size: 16px;
            font-weight: 400;
            color: #505050;
        }

        .deposit-price {
            font-size: 28px;
            font-weight: 600;
            color: #303030;
            display: flex;
        }
    }

    .address-heading {
        font-size: 18px;
        font-weight: 400;
        color: #505050;
        margin-left: 10px;
    }

    .property-heading {
        font-size: 20px;
        font-weight: 700;
        margin-top: 15px;
    }

    .property-box {
        margin-top: 20px;

    }

    .property-sub-heading {
        font-size: 14px;
        font-weight: 500;
        color: #505050;
        margin-bottom: 17px;
    }

    .feature-property-heading {
        font-size: 20px;
        font-weight: 600;
    }

    .feature-property-description {
        font-size: 16px;
        font-weight: 400;
    }

    .amenitie-box {
        margin: 0;
        padding: 0;
        display: inline-block;
        cursor: pointer;

        .amenitie-select {
            width: auto;
            height: 70px;
            display: flex;
            align-items: center;
            background: #F5F5F5;
            margin-top: 15px;
            margin-right: 10px;
            padding-right: 23px;

            .amenitie-select-heading {
                font-size: 20px;
                font-family: "Proxima Nova";
                padding-left: 10px;
            }
        }
    }
}

// .modal-calender-conatiner {

//     // padding: 15px;
//     .modal-avail-heading {
//         font-size: 24px;
//         font-weight: 600;
//     }

//     .inputField_calender{
//         display: flex;
//         width: 50%;
//         // padding-left: 20px;

//         .MuiInputBase-input {
//           font-weight: 600;
//           font-family: 'Proxima_nova_reg';
//         }
//       }

//     .slots {
//         width: 20px;
//         height: 20px;
//         background-color: #00BC29;
//         border-radius: 50%;
//     }

//     .footer-description {
//         font-size: 16px;
//         font-weight: 400;
//         font-family: 'Proxima_nova_reg';
//         padding-left: 10px;
//     }

// }

// .main_calender {

//     .react-datepicker__day-names {
//         display: none !important;
//     }

//     .react-datepicker__day--outside-month {
//         visibility: hidden !important;
//     }
// }

// .main_calender {

//     .react-datepicker__header {
//         background-color: #ffffff !important;
//         border-bottom: none !important;
//         width: 490px;
//         margin: auto;
//     }

//     .react-datepicker {
//         border: none;
//     }

//     .react-datepicker__week {
//         font-size: 20px;
//         justify-content: space-evenly;
//         display: flex;
//         font-family: 'Proxima_nova_reg';
//         padding-bottom: 20px
//     }

//     .react-datepicker__day--selected:hover {

//         background: #0075CA;
//         border-radius: 50%;
//         padding: 2px;
//     }

//     .react-datepicker__day--keyboard-selected {
//         border-radius: 50%;
//         padding: 2px;
//         background-color: #E5FFE5 !important;
//         border-radius: 50px !important;
//         color: #00BC29 !important;
//     }

//     .react-datepicker__day--selected {
//         background-color: #E5FFE5 !important;
//         border-radius: 50px !important;
//         color: #00BC29 !important;
//     }


//     .range-selected {
//         background-color: #E5FFE5;
//         border-radius: 50px;
//         color: #00BC29;
//     }


// }
.arrow-box {
    background-color: #ffffff;
    width: 60px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.carousel-container {
    position: relative;
    margin-top: 10px;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
}

.prev-arrow {
    right: calc(100% - 36px);
}

.next-arrow {
    left: calc(100% - 36px);
}

.carousel-item {
    margin: 0 1px;
}

.slick-slider {
    padding-top: 15px;
}

.slots {
    width: 20px;
    height: 20px;
    background-color: #00BC29;
    border-radius: 50%;
}

.footer_para {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Proxima_nova_reg';
    padding-left: 15px;
}

.modal-container {

    .calender_header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
}

.custom-header {


    .monthyear {
        font-size: 24px;
        font-family: 'Proxima_nova_reg';
        text-align: left;


    }

    .date_month {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-top: 5px;
    }

    .MuiFormControl-root {
        padding-bottom: 5px;



    }

}

.inputField_calender {
    display: flex;
    width: 50%;

    .MuiInputBase-input {
        font-weight: 600;
        font-family: 'Proxima_nova_reg';
    }
}


.react-datepicker__day-names {
    display: 'none'
}

.main_calender {

    .react-datepicker__header {
        background-color: #ffffff !important;
        border-bottom: none !important;
        width: 490px;
        margin: auto;
    }

    .react-datepicker {
        border: none;
    }

    .react-datepicker__week {
        font-size: 20px;
        justify-content: space-between;
        display: flex;
        font-family: 'Proxima_nova_reg';
        padding-bottom: 20px
    }

    .react-datepicker__day--selected:hover {

        background: #0075CA;
        border-radius: 50%;
        padding: 2px;
    }

    .react-datepicker__day--keyboard-selected {
        border-radius: 50%;
        padding: 2px;
        background-color: #E5FFE5 !important;
        border-radius: 50px !important;
        color: #00BC29 !important;
    }

    .react-datepicker__day--selected {
        background-color: #E5FFE5 !important;
        border-radius: 50px !important;
        color: #00BC29 !important;
    }


    .range-selected {
        background-color: #E5FFE5;
        border-radius: 50px;
        color: #00BC29;
    }

    .start-date {
        background-color: #E5FFE5;
        border-radius: 50px;
        color: #00BC29 !important;
    }

    .end-date {
        background-color: #E5FFE5;
        border-radius: 50px;
        color: #00BC29 !important;
    }

}

.main_calender {

    .react-datepicker__day-names {
        display: none !important;
    }

    .react-datepicker__day--outside-month {
        visibility: hidden !important;
    }
}

.carousel-item.selected img {
    border: 4px solid #0075CA;
    padding: 5px;
    width: 95%;
    outline: none;
}

.carousel-item.selected {
    outline: none;
}