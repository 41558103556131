.property-container {
    .owner-list-card {
        // width: 400px;
        margin-top: 50px;

        @media (max-width:575px) {
            margin: 0px !important;
        }

        .owner-main-heading {
            font-size: 16px;
            font-weight: 600;

            @media (max-width:575px) {
                margin-left: 10px !important;
            }
        }

        .property-owner-card {
            height: 400px;
            background-color: #ffffff;
            margin-top: 10px;
            overflow-y: scroll;

            .users-heading {
                padding-left: 10px;
                padding-top: 10px;

                .user-main-heading {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    padding-top: 7px;
                }

                .user-heading {
                    font-size: 16px;
                    font-weight: 500;
                    color: #909090;
                }
            }
        }

        .manager-card-without-result {
            width: 432px;
            height: 550px;
            background-color: #ffffff;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px dotted black;

            @media (max-width:575px) {
                width: 100% !important;
            }

            .result-heading {
                font-size: 20px;
                font-weight: 400;
                display: flex;
                justify-content: center;
            }
        }
    }


    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #909090;
        border-radius: 3px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #909090;
    }
}

@media (max-width:1200px) {
    .responsive-find-list {
        display: none !important;
    }

    .responsive-find-main {
        display: inline !important;
    }
}