.arrow-box-paymennt {
    background-color: #ffffff;
    width: 60px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.payment-detail {
    background-color: #ffffff;
    padding: 15px 20px;
    height: auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @media (max-width:1020px) {
        margin-top: 30px !important;
    }

    .payment-heading {
        font-size: 24px;
        font-weight: 600;
        border-bottom: 1.8px solid #e1e3e1;
    }

    .method-heading {
        font-size: 20px;
        font-weight: 600;
    }

    .card-container {
        width: 140px;
        height: 57px;
        border: 1.5px solid #505050;
        border-radius: 5px;

        .credit-heading {
            font-size: 14px;
            font-weight: 600;
        }
    }

    // .paypal-buttons {
    //     min-width: 125px !important;
    //     width: 125px !important;

    //     .paypal-button-container {
    //         min-width: 125px !important;
    //         width: 125px !important;
    //     }
    // }
}

.listing-detail {
    background-color: #ffffff;
    padding: 15px 20px;
    height: auto;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @media (max-width:1020px) {
        margin-top: 30px !important;
    }

    .listing-main-heading {
        font-size: 24px;
        font-weight: 600;
        border-bottom: 1.8px solid #e1e3e1;
    }

    .payment-card {
        padding: 10px 30px 15px 10px;

        .payment-descrption {
            font-size: 16px;
            font-weight: 400;
            color: #707070;
        }

        .price-payment {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
        }
    }
}