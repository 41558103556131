.property-assign-container {
    // margin-left: 20px;
    margin-top: 50px !important;

    @media (max-width:1199px) {
        margin-top: 20px !important;
    }

    .property-profile-seacrtion {
        // width: 800px;
        height: auto;
        background-color: #ffffff;
        padding: 15px 0 25px 0;
        box-shadow: 0px 2px 8px 0px #0000001F;

        @media (min-width:760px) and (max-width:770px) {
            margin-right: 20px !important;
        }

        .property-owner-heading {
            font-size: 20px;
            font-weight: 600;
            padding-top: 10px;
            color: #303030;
        }

        .select-dec {
            padding: 10px 40px 0 40px;

            .select-des-value {
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                color: #303030;
            }
        }

        // .phone-number {
        //     font-size: 16px;
        //     font-weight: 400;
        //     color: #707070;
        //     padding-left: 8px;
        // }

        // .box-msg {
        //     width: 180px;
        //     height: 44px;
        //     border: 0.5px solid #E0E0E0;
        //     margin-top: 20px;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;

        //     .message-heading {
        //         font-size: 16px;
        //         font-weight: 500;
        //         color: #666666;
        //         padding-left: 10px;
        //     }
        // }

        .phone-container {
            display: flex;
            justify-content: center;

            .phone-number {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
                padding-left: 8px;
                display: flex;
                // align-items: center;
            }
        }

        .msg-box {
            width: 180px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
        }

        .email-conatiner {
            display: none !important;

            .phone-number {
                font-size: 16px;
                font-weight: 400;
                color: #707070;
                padding-left: 8px;
                display: flex;
                align-items: center;
            }

        }
    }

    .list-assign-heading {
        font-size: 16px;
        font-weight: 600;
        margin: 20px 0 0 10px;
    }

    .owner-list-container {
        height: 475px;
        overflow-y: scroll;
        overflow-x: hidden;

    }
}

@media (max-width:690px) {
    .property-assign-container .property-profile-seacrtion .phone-container {
        display: none !important;
    }

    .property-assign-container .property-profile-seacrtion .email-conatiner {
        display: flex !important;
        justify-content: center !important;
    }
}