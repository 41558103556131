// .login {
//     height: fit-content;
//     min-height: 100vh;
//     display: flex;
//     justify-content: center;
//     font-family: Proxima_nova_medium;

//     .middleContent {
//         margin-top: 50px;

//         .arrow-back {
//             display: none;
//         }

//         .logo {
//             margin-bottom: 30px;
//             text-align: center;

//             img {
//                 max-width: 166px;
//             }
//         }
//     }

//     .mainFields {
//         width: 800px;

//         @media (min-width:431px) and (max-width:540px) {
//             margin-left: -5px !important;
//         }

//         @media (min-width:360px) and (max-width:376px) {
//             margin-left: 12px !important;
//         }

//         @media (max-width:375px) {
//             margin-left: 8px !important;
//         }

//         .get-regsister {
//             @media (max-width:540px) {
//                 margin-left: -10px !important;
//             }
//         }

//         .login-button {
//             @media (max-width:540px) {
//                 margin-left: 6px !important;
//                 margin-right: -6px !important;
//             }
//         }

//         .uploadPhoto {
//             display: flex;
//             justify-content: center;

//             .uploadInfo {
//                 width: 127px;
//                 height: 127px;
//                 border: 1px solid #2F86D1;
//                 border-radius: 50% !important;
//                 cursor: pointer;
//                 box-shadow: 1px 4px #bdbbbb;

//                 .uploadText {
//                     margin-top: 10px;
//                     color: #2F86D1;
//                     font-family: "Inter", Arial, sans-serif;
//                     font-style: normal;
//                     font-weight: 500;
//                     font-size: 16px !important;
//                     line-height: 16px;
//                 }
//             }
//         }
//     }

//     @media screen and (max-width: 576px) {
//         .middleContent {
//             margin-top: 40px;
//             padding: 0 10px;
//         }

//         .logo {
//             margin-bottom: 4vh !important;
//         }

//         .logoImg {
//             width: 120px;
//         }
//     }

//     .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
//         border-bottom: 1px solid #EAEAEA;
//     }

//     .css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
//         border-bottom: 1px solid #EAEAEA;
//     }
// }

// @media (max-width:1024px) {
//     .login .middleContent .arrow-back {
//         display: flex !important;
//     }
// }

// @media screen and (max-width: 1200px) {
//     .mainFields {
//         width: 60vw !important;
//     }

//     // .login {

//     //     .logo {
//     //         display: none !important;
//     //     }
//     // }

// }

// @media screen and (max-width: 576px) {
//     .mainFields {
//         width: 90vw !important;
//     }
// }

// .MuiInputBase-root {
//     padding: 0 !important;
// }

// .organisationlist {
//     ::-webkit-scrollbar {
//         width: 10px;
//     }
// }

// /* Specific Media Queries for devices */

// @media screen and (max-width: 375px) {

//     /* iPhone Expensive portrait, iPhone 6-8 portrait */
//     .mainFields {
//         width: 90vw !important;
//     }

//     .middleContent {
//         margin-top: 20px;
//     }
// }

// @media screen and (max-width: 734px) {

//     /* iPhone Expensive landscape */
//     .mainFields {
//         width: 80vw !important;
//     }

//     .middleContent {
//         margin-top: 30px;
//     }
// }

// @media screen and (max-width: 412px) {

//     /* Android (Pixel 2) portrait */
//     .mainFields {
//         width: 85vw !important;
//     }

//     .middleContent {
//         margin-top: 20px;
//     }
// }

// @media screen and (max-width: 684px) {

//     /* Android (Pixel 2) landscape */
//     .mainFields {
//         width: 70vw !important;
//     }

//     .middleContent {
//         margin-top: 30px;
//     }
// }

// @media screen and (max-width: 667px) {

//     /* iPhone 6-8 landscape */
//     .mainFields {
//         width: 75vw !important;
//     }

//     .middleContent {
//         margin-top: 30px;
//     }
// }

// @media screen and (max-width: 414px) {

//     /* iPhone 6-8 Plump portrait */
//     .mainFields {
//         width: 85vw !important;
//     }

//     .middleContent {
//         margin-top: 20px;
//     }
// }

// @media screen and (max-width: 736px) {

//     /* iPhone 6-8 Plump landscape */
//     .mainFields {
//         width: 75vw !important;
//     }

//     .middleContent {
//         margin-top: 30px;
//     }
// }

// @media screen and (max-width: 768px) {

//     /* iPad portrait */
//     .mainFields {
//         width: 70vw !important;
//     }

//     .middleContent {
//         margin-top: 40px;
//     }
// }

// @media screen and (max-width: 1024px) {

//     /* iPad landscape */
//     .mainFields {
//         width: 85vw !important;
//     }

//     .middleContent {
//         margin-top: 40px;
//     }
// }

.login {
    height: fit-content;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    font-family: Proxima_nova_medium;

    .middleContent {
        margin-top: 50px;

        .arrow-back {
            display: none;
        }

        .logo {
            margin-bottom: 30px;
            text-align: center;


            img {
                max-width: 166px;
            }

        }
    }

    .mainFields {
        width: 800px;

        @media (min-width:431px) and (max-width:540px) {
            margin-left: -5px !important;
        }

        @media (min-width:360px) and (max-width:376px) {
            margin-left: 12px !important;
        }

        @media (max-width:375px) {
            margin-left: 8px !important;
        }

        .get-regsister {
            @media (max-width:540px) {
                margin-left: -10px !important;
            }
        }

        .login-button {
            @media (max-width:540px) {
                margin-left: 6px !important;
                margin-right: -6px !important;
            }
        }

        .uploadPhoto {
            display: flex;
            justify-content: center;

            .uploadInfo {
                width: 127px;
                height: 127px;
                border: 1px solid #2F86D1;
                border-radius: 50% !important;
                cursor: pointer;
                box-shadow: 1px 4px #bdbbbb;

                .uploadText {
                    margin-top: 10px;
                    color: #2F86D1;
                    font-family: "Inter", Arial, sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px !important;
                    line-height: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 576px) {
        .middleContent {
            margin-top: 40px;
            padding: 0 10px;
        }

        .logo {
            margin-bottom: 4vh !important;
        }

        .logoImg {
            width: 120px;
        }
    }

    .css-1eed5fa-MuiInputBase-root-MuiInput-root::before {
        border-bottom: 1px solid #EAEAEA;
    }

    .css-n70jm4-MuiInputBase-root-MuiInput-root-MuiSelect-root::before {
        border-bottom: 1px solid #EAEAEA;
    }
}

@media screen and (max-width: 1200px) {
    .mainFields {
        width: 60vw !important;
    }

    // .login {

    //     .logo {
    //         display: none !important;
    //     }
    // }
}

@media screen and (max-width: 576px) {
    .mainFields {
        width: 90vw !important;
    }
}

.MuiInputBase-root {
    padding: 0 !important;
}

.organisationlist {
    ::-webkit-scrollbar {
        width: 10px;
    }
}

/* Specific Media Queries for devices */

@media screen and (max-width: 375px) {

    /* iPhone Expensive portrait, iPhone 6-8 portrait */
    .mainFields {
        width: 90vw !important;
    }

    .middleContent {
        margin-top: 20px;
    }
}

@media screen and (max-width: 734px) {

    /* iPhone Expensive landscape */
    .mainFields {
        width: 80vw !important;
    }

    .middleContent {
        margin-top: 30px;
    }
}

@media screen and (max-width: 412px) {

    /* Android (Pixel 2) portrait */
    .mainFields {
        width: 85vw !important;
    }

    .middleContent {
        margin-top: 20px;
    }
}

@media screen and (max-width: 684px) {

    /* Android (Pixel 2) landscape */
    .mainFields {
        width: 70vw !important;
    }

    .middleContent {
        margin-top: 30px;
    }
}

@media screen and (max-width: 667px) {

    /* iPhone 6-8 landscape */
    .mainFields {
        width: 75vw !important;
    }

    .middleContent {
        margin-top: 30px;
    }
}

@media screen and (max-width: 414px) {

    /* iPhone 6-8 Plump portrait */
    .mainFields {
        width: 85vw !important;
    }

    .middleContent {
        margin-top: 20px;
    }
}

@media screen and (max-width: 736px) {

    /* iPhone 6-8 Plump landscape */
    .mainFields {
        width: 75vw !important;
    }

    .middleContent {
        margin-top: 30px;
    }
}

@media screen and (max-width: 768px) {

    /* iPad portrait */
    .mainFields {
        width: 70vw !important;
    }

    .middleContent {
        margin-top: 40px;
    }
}

@media screen and (max-width: 1024px) {

    /* iPad landscape */
    .mainFields {
        width: 85vw !important;
    }

    .middleContent {
        margin-top: 40px;
    }

    .login .middleContent .arrow-back {
        display: flex !important;
    }
}

.mobile-contact {
    font-size: 20px;
}

@media (max-width:375px) {
    .mobile-contact {
        font-size: 16px;
    }

    .mobile-text {
        font-size: 13px;
    }
}