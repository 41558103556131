.subscription-container {
    // width: 1000px;
    height: auto;
    background-color: #FFFFFF;
    padding: 20px 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    @media (max-width:575px) {
        margin: 0px 10px 10px 10px !important;
    }

    .current-heading {
        font-size: 18px;
        font-weight: 400;
    }

    .monthly-heading {
        font-size: 24px;
        font-weight: 600;
        color: #0075CA;
        margin-top: 20px;
    }

    .price-manager-heading {
        font-size: 40px;
        font-weight: 700;

        @media (max-width:575px) {
            font-size: 32px !important;
        }
    }

    .month-manager-heading {
        display: flex;
        align-self: center;
        color: #919CA5;
        margin-left: 5px;
        margin-top: 5px;
        font-size: 20px;
        font-weight: 400;
    }

    .payment-description {
        font-size: 16px;
        font-weight: 400;
        color: #919CA5;

        @media (max-width:575px) {
            font-size: 14px !important;
        }
    }

    .date-description {
        font-size: 20px;
        font-weight: 600;
        color: #505050;

        @media (max-width:575px) {
            font-size: 16px !important;
        }
    }
}

.myoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(145, 145, 148, 0.9);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal-subscription-heading {
    font-size: 24px;
    font-weight: 700;
    padding-top: 15px;
}

.modal-subscription-description {
    font-size: 18px;
    font-weight: 400;
    color: #2E2E2E;
    padding-top: 10px;
}

.stay-heading {
    font-size: 18px;
    font-weight: 400;
    color: #2E2E2E;
    display: flex;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
}