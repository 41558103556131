.chipButton {
  button {
    cursor: pointer;
    background: transparent;
    border: 0;
  }

  button:focus {
    outline: none;
  }

  button img {
    width: 20px;
    height: 20px;
  }

  input[type='file'] {
    display: none;
  }

  .chip-value {
    color: "var(--primary)";
    background-color: "var(--secondary)";
    border-radius: 4px;
    margin-bottom: 10px !important;
  }

  .MuiButtonBase-root {
    color: #0075CA !important;
    background-color: #E7F4FE !important;
    border-radius: 4px;
    margin-bottom: 10px !important;
    // width: 70% !important;
  }
}

@media (min-width:1024) and (max-width:1300px) {
  .chipButton .MuiButtonBase-root {
    width: 270px !important;
  }
}