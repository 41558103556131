.card-detail-container {
    margin-top: 20px;
    z-index: 1;

    // .MuiMobileStepper-root {
    //     // position: absolute;
    //     display: flex;
    //     align-items: center;
    //     background-color: transparent;
    //     color: #ffff !important;
    //     margin-top: -130px !important;
    //     margin-left: -20px !important;
    //     margin-right: -23px !important;

    //     @media (min-width:770px) and (max-width:915px) {
    //         margin-top: -100px !important;
    //     }

    //     @media (min-width:915px) and (max-width:1024px) {
    //         margin-top: -100px !important;
    //     }

    //     @media (min-width:431px) and (max-width:575px) {
    //         margin-top: -190px !important;
    //     }
    // }

    // .MuiPaper-root {
    //     background-color: transparent !important;
    //     color: #ffff !important;
    // }

    // .MuiButtonBase-root {
    //     display: inline-block;
    //     justify-content: inherit !important;
    // }

    .MuiMobileStepper-root {
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #ffff !important;
        margin-top: -130px !important;
        margin-left: -20px !important;
        margin-right: -23px !important;

        @media (min-width:770px) and (max-width:915px) {
            margin-top: -100px !important;
        }

        @media (min-width:915px) and (max-width:1024px) {
            margin-top: -100px !important;
        }

        @media (min-width:431px) and (max-width:575px) {
            margin-top: -190px !important;
        }
    }

    .MuiPaper-root {
        background-color: transparent !important;
        color: #ffff !important;
    }

    .MuiButtonBase-root {
        display: inline-block;
        justify-content: inherit !important;
        border: none !important;
    }

    .ManagerRequest-heading {
        padding-top: 90px;
        color: #707070;
        font-size: 16px;
        font-family: "Proxima Nova";
        display: flex;
        justify-content: center;
    }


    .rent-box {
        padding-top: 32% !important;

        .rental-price {
            font-size: 22px;
            font-weight: 600;
            padding: 10px 6px 5px 2px;
            font-family: "Proxima Nova";
        }

        .rent-heading {
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 25px;
            font-weight: 600;
            padding: 8px 0 5px 10px;
            font-family: "Proxima Nova";
        }

    }

    .deposit-type {
        display: none !important;
    }

    .mobile-type {
        display: none !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

    }

    .view-ipaid {
        display: none !important;

        @media (min-width:991px) and (max-width:1025px) {
            display: flex !important;
        }
    }

    .view-heading {
        font-size: 18px;
        font-weight: 600;
        color: #2F86D1;
        text-decoration: underline;
    }

    .ManagerRequest-card-detail {

        @media (max-width:575px) {
            padding-left: 10px !important;
            padding-right: 10px !important;
            padding-bottom: 10px !important;
        }

        .ManagerRequest-user-heading {
            font-size: 23px;
            font-weight: 600;
            color: #2F86D1;
        }

        .ManagerRequest-user-month {
            display: flex;
            align-self: center;
            color: #2F86D1;
            margin-top: 5px;
            font-size: 16px;
            font-weight: 600;
        }

        .user-heading {
            font-size: 18px !important;
            font-weight: 600 !important;
            font-family: "Proxima Nova";
        }

        .user-dot {
            font-size: 20px;
            font-weight: 800;
            margin: -6px 10px 0 10px;
        }

        .property-owner {
            color: #707070;
            font-size: 18px;
            font-weight: 500;
        }

        .request-profile {
            background-color: #2F86D1;
            color: #ffffff;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
            // padding-right: 3px;
            padding-top: 3px;
            cursor: pointer;
            margin-right: 20px;
        }

        .categroy-heading {
            color: #707070;
            font-size: 14px;
            font-weight: 500;
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .categroy-heading-type {
            color: #707070;
            font-size: 14px;
            font-weight: 500;
            margin-left: 8px;
            font-family: "Proxima_nova_medium";
            display: flex;
            align-items: center;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
        }

        .category-value {
            font-size: 16px;
            font-weight: 600;
            margin-left: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #000000;
        }

        .select-value-heading {
            font-size: 16px;
            font-weight: 600;
            margin-top: 10px;
        }
    }
}

@media (min-width:1312px) and (max-width:1812px) {
    .card-detail-container .view-container {
        display: none !important;
    }
}

@media (min-width:575px) and (max-width:1133px) {
    .card-detail-container .view-container {
        display: none !important;
    }
}

@media (min-width:575px) and (max-width:915px) {
    .card-detail-container .chat-card-container {
        display: none !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .card-detail-container .rent-box {
        padding-top: 42% !important;
    }

    .card-detail-container .deposit-type {
        padding-top: 42% !important;
    }

    .card-detail-container .view-ipaid {
        display: flex !important;
    }

    .favourite-listing .listing-manager-fav .listing-card-detail .profile-value {
        width: 70px !important;
        height: 70px !important;
    }

    .favourite-listing.listing-manager-fav .listing-card-detail .user-heading {
        font-size: 16px !important;
    }

    .favourite-listing.listing-manager-fav .listing-card-detail .category-value {
        font-size: 14px !important;
    }

    // .empty-fav-list {
    //     width: 595px !important;
    // }

}

@media (min-width:808px) and (max-width:913px) {
    .card-detail-container .rent-box {
        display: none !important;
    }


    .card-detail-container .deposit-type {
        padding-top: 23% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 10px 6px 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            // padding: 8px 0 5px 10px;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (min-width:770px) and (max-width:808px) {
    .card-detail-container .rent-box {
        display: none !important;
    }
}

@media (min-width:1200px) and (max-width:1523px) {
    .card-detail-container .rent-box {
        padding-top: 33% !important;
    }

}

@media (min-width:575px) and (max-width:768px) {
    .card-detail-container .MuiMobileStepper-root {
        margin-top: -100px !important;
    }

    .card-detail-container .rent-box {
        display: none !important;
    }

    .card-detail-container .deposit-type {
        padding-top: 30% !important;
        display: inline-block !important;

        .rental-heading {
            padding-top: 70px;
            color: #707070;
            font-size: 16px;
            font-family: "Proxima Nova";
            display: flex;
            justify-content: center;
        }

        .rental-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }

        .rent-heading {
            font-size: 16px;
            font-weight: 500;
            color: #707070;
        }

        .deposit-price {
            font-size: 16px;
            font-weight: 600;
            padding-left: 7px;
            font-family: Proxima_nova_reg;
        }
    }
}

@media (max-width:575px) {
    .card-detail-container .mobile-type {
        margin-top: 10px !important;
        display: flex !important;
    }
}

@media (min-width:430px) and (max-width:575px) {
    .card-detail-container .rent-box {
        display: none !important;
    }

    .card-detail-container .ManagerRequest-card-detail .section-profile {
        margin-top: 155px !important;
    }
}

@media (max-width:430px) {
    .card-detail-container .rent-box {
        display: none !important;
    }

    .card-detail-container .ManagerRequest-card-detail .section-profile {
        margin-top: 85px !important;
    }
}

.modal-upload-image-section {
    .slick-slider {
        padding-top: 0px !important;
    }

    .custom-image-upload {
        position: absolute !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .prev-arrows-image {
        right: calc(100% - 130px);
        z-index: 1;

        @media (max-width:1030px) {
            display: none !important;
            right: calc(100% - 60px);
        }
    }

    .next-arrows-image {
        left: calc(100% - 130px);

        @media (max-width: 1030px) {
            display: none !important;
            left: calc(100% - 60px);
        }
    }

    .slick-dots {
        bottom: 0px !important;

        @media (max-width:375px) {
            bottom: 10px !important;
        }
    }

    .slick-dots li button:before {
        color: white !important;
        font-size: 20px !important;
    }

}