// .back {
//     position: absolute;
//     bottom: 68px;
//     left: 70px;
//     cursor: pointer;
//     display: inline-block;
//     height: fit-content;
// }

// .back-list {
//     left: 70px;
//     cursor: pointer;
// }

// .smallBack {
//     font-family: Proxima_nova_medium;
//     display: none;
//     top: 0;
//     margin-left: 0;
//     padding: 0 14px;
//     width: 100%;
// }

// // @media screen and (max-width:1200px) {
// //     .back {
// //         margin-top: 40px;
// //         top: 0;
// //         // margin-left: 30px;
// //         display: none;
// //     }

// //     .smallBack {
// //         display: flex;
// //     }
// // }
// @media screen and (max-height:850px) {
//     .back {
//         position: relative !important;
//         bottom: 0px !important;
//         right: 0px !important;
//         margin-top: 0px !important;
//         float: left;
//         clear: both;
//     }

// }

.back {
    position: absolute;
    bottom: 68px;
    left: 70px;
    cursor: pointer;
    display: inline-block;
    height: fit-content;
}

.back-list {
    left: 70px;
    cursor: pointer;
}

.smallBack {
    font-family: Proxima_nova_medium;
    display: none;
    top: 0;
    margin-left: 0;
    padding: 0 14px;
    width: 100%;
}

// @media screen and (max-height: 850px) {
//     .back {
//         position: relative !important;
//         bottom: 0px !important;
//         right: 0px !important;
//         margin-top: 0px !important;
//         float: left;
//         clear: both;
//     }
// }

/* iPhone expensive portrait */
@media screen and (max-width: 375px) {
    .back {
        margin-top: 40px !important;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* iPhone expensive landscape */
@media screen and (max-width: 734px) and (min-width: 376px) {
    .back {
        margin-top: 20px !important;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex !important;
    }
}

/* Android (Pixel 2) portrait */
@media screen and (max-width: 412px) {
    .back {
        margin-top: 40px !important;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* Android (Pixel 2) landscape */
@media screen and (max-width: 684px) and (min-width: 413px) {
    .back {
        margin-top: 20px !important;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* iPhone 6-8 portrait */
@media screen and (max-width: 375px) {
    .back {
        margin-top: 40px !important;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* iPhone 6-8 landscape */
@media screen and (max-width: 667px) and (min-width: 376px) {
    .back {
        margin-top: 20px !important;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* iPhone 6-8 Plump portrait */
@media screen and (max-width: 414px) {
    .back {
        margin-top: 40px !important;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* iPhone 6-8 Plump landscape */
@media screen and (max-width: 736px) and (min-width: 415px) {
    .back {
        margin-top: 20px;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* iPad portrait */
@media screen and (max-width: 768px) {
    .back {
        margin-top: 40px;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}

/* iPad landscape */
@media screen and (max-width: 1024px) and (min-width: 769px) {
    .back {
        margin-top: 20px;
        top: 0;
        display: none !important;
    }

    .smallBack {
        display: flex;
    }
}