.assign-list-container {

    .search-btn {
        background-color: #2F86D1;
        cursor: pointer;
        display: flex;
        align-items: center;
        // margin: 5px 4px 5px 0;
        height: 48px;
        width: 140px;
        padding-left: 18px;
        border-radius: 4px;
        margin-right: 5px;

        @media (max-width:575px) {
            width: 48px !important;
            justify-content: center !important;
            padding-left: 0px !important;
        }
    }

    .MuiInputBase-root {
        display: flex !important;
        justify-content: space-between !important;
    }
}

.filter_items {
    display: block !important;
    padding: 10px !important;
}