.subscription-package-container {
    margin-top: 20px;
    // width: 1000px;
    height: auto;
    background-color: #FFFFFF;
    padding: 20px 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    .current-heading {
        font-size: 18px;
        font-weight: 400;
    }

    .active-heading {
        font-size: 24px;
        font-weight: 600;
        color: #919CA5;
        margin-top: 25px;
    }

    .active-description {
        font-size: 16px;
        font-weight: 400px;
        margin-top: 10px;
    }

    .monthly-heading {
        font-size: 24px;
        font-weight: 600;
        color: #0075CA;
        margin-top: 20px;
    }

    .price-manager-heading {
        font-size: 40px;
        font-weight: 700;

        @media (max-width:575px) {
            font-size: 32px !important;
        }
    }

    .month-manager-heading {
        display: flex;
        align-self: center;
        color: #919CA5;
        margin-left: 5px;
        margin-top: 5px;
        font-size: 20px;
        font-weight: 400;
    }

    .payment-description {
        font-size: 16px;
        font-weight: 400;
        color: #919CA5;

        @media (max-width:575px) {
            font-size: 14px !important;
        }
    }

    .date-description {
        font-size: 20px;
        font-weight: 600;
        color: #505050;

        @media (max-width:575px) {
            font-size: 16px !important;
        }
    }

    .change-plan-heading {
        font-size: 24px;
        font-weight: 700;
        padding-top: 40px;
    }

    .change-plan-description {
        font-size: 20px;
        font-weight: 400;
        padding-top: 10px;

        @media (min-width:760px) {
            margin-bottom: 10px !important;
        }
    }

    .subscription-box {
        // display: flex;
        // justify-content: center;
        // flex-direction: column;

        .item-heading {
            display: flex;
            justify-content: center;
            font-size: 20px;
            font-weight: 600;
            margin-top: 15px;
            margin-bottom: 10px;
            color: #505050;
        }

        .item-below-description {
            margin-top: 5px;
            margin-left: 2px;
            font-size: 16px;
            font-weight: 500;
            color: #919CA5;
        }

        .subscribe-button {
            cursor: pointer;
            height: 167px;
            padding-top: 20px;

            @media (max-width:575px) {
                margin-top: 20px !important;
                height: 120px;
                padding-top: 14px;
            }

            .amount-term {
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0075CA;
                font-size: 24px;
                font-weight: 700;
            }

            .listing-amount {
                color: #505050;
                font-weight: 700;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-top: 20px;

                @media (max-width:575px) {
                    padding-top: 12px !important;
                }
            }
        }
    }

    .subscription-button {
        border: none;
        background-color: white;
        font-size: 24px;
        padding-top: 20px;
        font-weight: 600;
    }

    .Manager-Button {
        display: flex;
        justify-content: center;
        padding-top: 10px;
        gap: 10px;
    }

    .Manager-SubscriptionButton {
        // width: 40%;
        border: 1px solid black;
        height: 167px !important;
        cursor: pointer;
    }

    .subscription-price {
        font-size: 24px;
        font-weight: 700;
        padding-top: 20px;
    }

    .Manager-freeTrial {
        font-size: 20px;
        padding: 10px;
        color: #505050
    }

    .checkout-btn {
        margin-top: 45px;
        margin-bottom: 7%;
    }
}