.AcceptButton {
    color: rgb(255, 255, 255);
    width: 164px;
    height: 50px;
    border-radius: 4px;
    font-weight: 600;
    background: #0075CA;
    display: flex;
    font-size: 17px;
    align-items: start;
    justify-content: center;
    vertical-align: center;
    padding-top: 13px;
    cursor: pointer;
}

.RejectRequest {
    color: #2F86D1;
    width: 150px;
    height: 50px;
    border-radius: 4px;
    font-weight: 600;
    background: #fff;
    display: flex;
    font-size: 17px;
    align-items: start;
    justify-content: center;
    vertical-align: center;
    padding-top: 13px;
    border: 2px solid #2F86D1;
    cursor: pointer;
}