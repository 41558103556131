// .empty-details {
//     // width: 900px;
//     background: white;
//     height: 306px;
//     margin-top: 20px;
//     border: 1px dashed black;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     .empty-heading {
//         font-size: 20px;
//         font-weight: 400;
//         font-family: 'Proxima_nova_medium' !important;
//     }

//     .empty-description {
//         font-size: 16px;
//         font-weight: 400;
//         display: flex;
//         justify-content: center;
//     }
// }

.empty-details {
    width: 100%;
    background: white;
    height: 230px;
    // margin-left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border: 1px dashed black;

    @media (width:375px) and (height:812px) {
        margin-bottom: 320px !important;
    }

    @media (min-width:420px) and (max-width:431px) {
        margin-bottom: 440px !important;
    }

    @media (min-width:400px) and (max-width:413px) {
        margin-bottom: 420px !important;
    }

    @media (min-height:860px) and (max-height:897px) {
        margin-bottom: 400px !important;
    }

    @media (min-height:700px) and (max-height:720px) {
        margin-bottom: 286px !important;
    }

    @media (min-height:721px) and (max-height:740px) {
        margin-bottom: 240px !important;
    }

    @media (min-width:376px) and (max-width:391px) {
        margin-bottom: 350px !important;
    }


    @media (width:375px) and (height:667px) {
        margin-bottom: 177px !important;
    }

    @media (max-width:360px) {
        margin-bottom: 250px !important;
    }

    .empty-heading {
        font-size: 20px;
        font-weight: 400;
        font-family: 'Proxima_nova_medium' !important;
    }

    .empty-description {
        font-size: 16px;
        font-weight: 400;
        display: flex;
        justify-content: center;
    }
}